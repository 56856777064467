import { uploadFile, gaLogEvent } from "./firebase.js";
import initUploadForm from "./upload-form";

const $form = document.getElementById("upload-form");
const $progress = document.getElementById("upload-progress");
const $success = document.getElementById("upload-success");

async function fileSelected(file) {
  // console.log("fileSelected file", file);
  gaLogEvent("file_selected", { type: file.type, size: file.size });
  $progress.querySelector(".file-meta-name").textContent = file.name;
  $progress.querySelector(".file-meta-size").textContent = file.size + " bytes";
  $progress.querySelector(".file-meta-type").textContent = file.type;
  $form.classList.add("hidden");
  $progress.classList.remove("hidden");
  const data = await uploadFile(file);
  const shareUrl = `${window.location.protocol}//${window.location.host}/f/${data.fileId}`;
  // console.log("uploaded file", data);
  gaLogEvent("file_uploaded", { type: file.type, size: file.size });
  $success.querySelector(".file-meta-name").textContent = data.name;
  $success.querySelector(".file-meta-name").setAttribute("href", shareUrl);
  $success.querySelector(".file-meta-size").textContent = data.size + " bytes";
  $success.querySelector(".file-meta-type").textContent = data.type;
  const qrElement = $success.querySelector("#qr-code");
  qrElement.src = `https://chart.googleapis.com/chart?chs=240x240&cht=qr&chl=${encodeURIComponent(
    shareUrl
  )}`;
  qrElement.classList.remove("hidden");

  const $shareLinkInput = $success.querySelector("#file-share-url-input");
  $shareLinkInput.value = shareUrl;
  function copyClipboard() {
    gaLogEvent("copy_clipboard", { page: "home" });
    $shareLinkInput.select();
    document.execCommand("copy");
  }
  $success
    .querySelector(".copy-clipboard")
    .addEventListener("click", copyClipboard);

  $progress.classList.add("hidden");
  $success.classList.remove("hidden");
}

initUploadForm($form, fileSelected);
gaLogEvent("home_page");
