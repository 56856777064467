const initUploadForm = (el, onFileSelected) => {
  const $form = el;
  const $fileInput = $form.querySelector("#upload-file");
  const $uploadButton = $form.querySelector("#upload-button");
  const $dropbox = document.body;
  $dropbox.classList.add("dropbox");

  function stopEvent(e) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
  function dragenter(e) {
    return stopEvent(e);
  }
  function dragover(e) {
    return stopEvent(e);
  }
  function drop(e) {
    var dt = e.dataTransfer;
    $fileInput.files = dt.files;
    fileSelected();
    return stopEvent(e);
  }
  function uploadButtonClick(e) {
    // Open Upload file dialog
    var evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    $fileInput.dispatchEvent(evt);
  }

  function fileSelected() {
    // when a file has been selected for upload
    // console.log("fileSelected file", file);
    var file = $fileInput.files[0];
    if (file) {
      onFileSelected(file);
    } else {
      return false;
    }
  }
  $dropbox.addEventListener("dragenter", dragenter, false);
  $dropbox.addEventListener("dragover", dragover, false);
  $dropbox.addEventListener("drop", drop, false);

  $uploadButton.addEventListener("click", uploadButtonClick);
  $fileInput.addEventListener("change", fileSelected);
};

export default initUploadForm;
